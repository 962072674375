import {Container, SimpleGrid, Text, ThemeIcon, Title} from "@mantine/core";
import {
  IconTicket,
  IconCompass,
  IconSearch,
  IconBell,
  IconPencil,
} from "@tabler/icons-react";
import React from "react";

import classes from "./Features.module.scss";

export const FEATURES_DATA = [
  {
    icon: IconSearch,
    title: "Search with ease",
    description:
      "Frustrated with figuring out how to search agency web sites? We make it easy to find what you want in one location.",
  },
  {
    icon: IconCompass,
    title: "Discover what's nearby",
    description:
      "Discover available camp sites nearby. Great for spontaneous trips!",
  },
  {
    icon: IconPencil,
    title: "Plan your trips better",
    description:
      "Have specific dates and amenities in mind? We'll help you plan your trips better.",
  },
  {
    icon: IconTicket,
    title: "Secure your reservation",
    description: "Finding available camp sites has never been easier.",
  },
  {
    icon: IconBell,
    title: "Scanning and alerts",
    description:
      "Want an advantage getting a reservation if someone cancels? We'll check for you and send you alerts.",
  },
];

export interface FeatureProps {
  icon: React.FC<any>;
  title: React.ReactNode;
  description: React.ReactNode;
}

export function Feature({icon: Icon, title, description}: FeatureProps) {
  return (
    <div>
      <ThemeIcon variant="light" size={40} radius={40}>
        <Icon size={18} stroke={1.5} />
      </ThemeIcon>
      <Text mt="sm" mb={7}>
        {title}
      </Text>
      <Text size="sm" c="dimmed" lh={1.6}>
        {description}
      </Text>
    </div>
  );
}

export default function Features() {
  return (
    <Container className={classes.wrapper}>
      <h2 className={classes.title}>Hard to find available camp sites?</h2>

      <Container size={560} p={0}>
        <Text size="sm" className={classes.description}>
          We&apos;ll help you find and reserve the camp site you want on the
          dates you want.
        </Text>
      </Container>

      <SimpleGrid
        mt={60}
        cols={{base: 1, sm: 2, md: 3}}
        spacing={{base: "xl", md: 50}}
        verticalSpacing={{base: "xl", md: 50}}>
        {FEATURES_DATA.map((feature, index) => (
          <Feature {...feature} key={index} />
        ))}
      </SimpleGrid>
    </Container>
  );
}
