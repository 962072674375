import {track} from "@amplitude/analytics-browser";
import {useQuery} from "@apollo/client";
import {
  faExclamationTriangle,
  faSearch,
  faCalendarDays,
  faPeopleGroup,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Button,
  Container,
  Group,
  NumberInput,
  Overlay,
  Text,
  Title,
} from "@mantine/core";
import {DatesProvider, DatePickerInput} from "@mantine/dates";
import dayjs from "dayjs";
import {Formik, FormikErrors, getIn, setIn} from "formik";
import {isEmpty} from "lodash";
import type {NextPage} from "next";
import Head from "next/head";
import {useRouter} from "next/router";
import React, {useEffect} from "react";

import classes from "./index.module.scss";
import Features from "../components/Features";
import PlacePicker from "../components/PlacePicker";
import {
  SearchState,
  searchStateToQueryString,
} from "../components/Search/types";
import {APP_NAME, APP_DESCRIPTION, DEFAULT_LOCATION_ZOOM} from "../constants";
import * as queries from "../graphql/queries";
import {getLogger} from "../logging";

const log = getLogger(__filename);

const HomePage: NextPage & {whyDidYouRender?: boolean} = () => {
  const router = useRouter();
  const {data: {search: {maxDate = undefined} = {}} = {}} = useQuery(
    queries.GET_MAX_DATE
  );

  const today = dayjs();
  const dateFormat = "YYYY-MM-DD";
  const initialSearchState: Partial<SearchState> = {};

  useEffect(() => {
    track("Home Page Viewed");
  }, []);

  return (
    <>
      <Head>
        <meta name="description" content={APP_DESCRIPTION} />

        <meta property="og:title" content={APP_NAME} />
        <meta property="og:description" content={APP_DESCRIPTION} />
      </Head>

      <main>
        <div className={classes.wrapper}>
          <Overlay color="#000" opacity={0.65} zIndex={1} />

          <div className={classes.inner}>
            <Title className={classes.title}>
              Find{" "}
              <Text component="span" inherit className={classes.highlight}>
                your
              </Text>{" "}
              camp
            </Title>

            <Container size={640}>
              <Text size="lg" className={classes.description}>
                Find and reserve your ideal tent, RV, or glamping camp site.
              </Text>
            </Container>

            <div className={classes.controls}>
              <Formik<Partial<SearchState>>
                initialValues={initialSearchState}
                validate={values => {
                  let errors: FormikErrors<Partial<SearchState>> = {};

                  // Validate date ranges and dependent fields
                  const filterMinDate = getIn(
                    values,
                    "searchInput.filter.dates.min"
                  );
                  const filterMaxDate = getIn(
                    values,
                    "searchInput.filter.dates.max"
                  );

                  if (filterMinDate || filterMaxDate) {
                    if (!filterMinDate || !filterMaxDate) {
                      errors = setIn(
                        errors,
                        "searchInput.filter.dates.min",
                        "Both begin and end dates must be entered together"
                      );
                    } else {
                      const beginDate = dayjs(filterMinDate, dateFormat);
                      const endDate = dayjs(filterMaxDate, dateFormat);
                      const maxDateObj = maxDate
                        ? dayjs(maxDate, dateFormat)
                        : null;

                      // today <= dates.min
                      if (beginDate.isBefore(today.startOf("day"))) {
                        errors = setIn(
                          errors,
                          "searchInput.filter.dates.min",
                          `Begin date is in the past: ${beginDate.format("L")}`
                        );
                      }

                      // Validate validate dates.max <= max(slots.date)
                      if (maxDateObj && endDate.isAfter(maxDateObj)) {
                        errors = setIn(
                          errors,
                          "searchInput.filter.dates.min",
                          `We don't have reservation data beyond ${maxDateObj.format(
                            "L"
                          )}`
                        );
                      }

                      // dates.min <= dates.max
                      if (!endDate.isSameOrAfter(beginDate)) {
                        errors = setIn(
                          errors,
                          "searchInput.filter.dates.min",
                          "End date must be greater than or the same as begin date"
                        );
                      }
                    }
                  }

                  // Validate people
                  const filterPeople = getIn(
                    values,
                    "searchInput.filter.people.max"
                  );
                  if (filterPeople && filterPeople < 1) {
                    errors = setIn(
                      errors,
                      "searchInput.filter.people.max",
                      "Group size must be at least 1"
                    );
                  }

                  const location = getIn(values, "center");
                  if (!location || !location.latitude || !location.longitude) {
                    errors = setIn(errors, "center", "Location is required");
                  }

                  return errors;
                }}
                onSubmit={async (values, {setSubmitting}) => {
                  await router.push(
                    `/search?${searchStateToQueryString(values)}`
                  );

                  setSubmitting(false);
                }}>
                {({
                  handleSubmit,
                  values,
                  errors,
                  isValidating,
                  isSubmitting,
                  submitCount,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Group>
                      <PlacePicker
                        size="lg"
                        placeholder="Where to?"
                        autoFocus
                        onSelection={async result => {
                          if (!result.location) {
                            log.error(
                              `No location on selected ${result.type} result ${result.id}`
                            );
                            return;
                          }

                          await setFieldValue("center", result.location);
                          await setFieldValue(
                            "searchInput.zoom",
                            DEFAULT_LOCATION_ZOOM
                          );
                        }}
                      />
                      <DatesProvider settings={{firstDayOfWeek: 0}}>
                        <DatePickerInput
                          size="lg"
                          type="range"
                          placeholder="Add dates"
                          numberOfColumns={2}
                          classNames={{wrapper: classes.datesInputWrapper}}
                          error={
                            submitCount > 0 &&
                            getIn(errors, "searchInput.filter.dates.min")
                          }
                          defaultDate={today.toDate()}
                          excludeDate={date => {
                            const dateObj = dayjs(date);
                            if (dateObj.isBefore(today.startOf("day"))) {
                              return true;
                            }

                            if (maxDate) {
                              const maxDateObj = dayjs(maxDate, dateFormat);
                              return dateObj.isAfter(maxDateObj);
                            }

                            return false;
                          }}
                          valueFormat="L"
                          value={[
                            getIn(values, "searchInput.filter.dates.min")
                              ? dayjs(
                                  getIn(values, "searchInput.filter.dates.min"),
                                  dateFormat
                                ).toDate()
                              : null,
                            getIn(values, "searchInput.filter.dates.max")
                              ? dayjs(
                                  getIn(values, "searchInput.filter.dates.max"),
                                  dateFormat
                                ).toDate()
                              : null,
                          ]}
                          onChange={value => {
                            const [beginDate, endDate] = value;
                            setFieldValue(
                              "searchInput.filter.dates.min",
                              beginDate
                                ? dayjs(beginDate).format(dateFormat)
                                : null
                            );
                            setFieldValue(
                              "searchInput.filter.dates.max",
                              endDate ? dayjs(endDate).format(dateFormat) : null
                            );
                          }}
                          clearable
                          leftSection={
                            <FontAwesomeIcon icon={faCalendarDays} />
                          }
                          leftSectionPointerEvents="none"
                        />
                      </DatesProvider>

                      <NumberInput
                        size="lg"
                        name="searchInput.filter.people.max"
                        placeholder="Add guests"
                        classNames={{wrapper: classes.peopleInputWrapper}}
                        error={
                          submitCount > 0 &&
                          getIn(errors, "searchInput.people.max")
                        }
                        min={1}
                        value={
                          getIn(values, "searchInput.filter.people.max") || ""
                        }
                        onChange={value =>
                          setFieldValue("searchInput.filter.people.max", value)
                        }
                        leftSection={<FontAwesomeIcon icon={faPeopleGroup} />}
                      />

                      <Button
                        size="lg"
                        variant="primary"
                        onClick={() => handleSubmit()}
                        disabled={isValidating}
                        loading={isSubmitting}
                        leftSection={
                          !isEmpty(errors) && submitCount > 0 ? (
                            <FontAwesomeIcon
                              icon={faExclamationTriangle}
                              beatFade
                            />
                          ) : (
                            <FontAwesomeIcon icon={faSearch} />
                          )
                        }>
                        Search
                      </Button>
                    </Group>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>

        <Features />
      </main>
    </>
  );
};

// HomePage.whyDidYouRender = true;

export default HomePage;
